import React from "react";
import "./LatestNews.scss";
import { Link } from "react-router-dom";
// Images
// tourist destinations
import damada from "../images/camp_damada.png";
import falls from "../images/saosao_falls.jpg";
import cave from "../images/cave.png";
// news
import news1 from "../images/news1.jpg";
import bids_1 from "../images/bids_1.jpg";
import news2 from "../images/news2.jpg";
import news3 from "../images/news3.jpg";

function LatestNews() {
  return (
    <div className="latestNews">
      <h1>Explore Nabunturan/Latest News</h1>
      <div className="wrapper">
        <div className="downContainer">
          <div className="card">
            <img className="card__image " src={bids_1} alt="Card" />
            <div className="card__content">
              <h2 className="card__title">
                Invitation To Bid <br />
                <br /> <br />
              </h2>
              <Link to="/invitation-to-bid">
                <button className="btn">See More</button>
              </Link>
            </div>
          </div>
          <div className="card">
            <img className="card__image" src={news1} alt="Card" />
            <div className="card__content">
              <h2 className="card__title">
                Signing of Memorandum of Understanding Between DHSUD and LGU
                Nabunturan Successful
              </h2>
              <Link to="/news">
                <button className="btn">See More</button>
              </Link>
            </div>
          </div>

          <div className="card">
            <img className="card__image " src={news3} alt="Card" />
            <div className="card__content">
              <h2 className="card__title">
                National Evaluation sa Department of Health(DOH) <br />
                <br />
              </h2>
              <Link to="/news">
                <button className="btn">See More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="upContainer">
          <div className="card">
            <img className="card__image" src={damada} alt="Card" />
            <div className="card__content">
              <h2 className="card__title">Camp Damada</h2>
              <Link to="/tourist-destination">
                <button className="btn">See More</button>
              </Link>
            </div>
          </div>
          <div className="card">
            <img className="card__image " src={falls} alt="Card" />
            <div className="card__content">
              <h2 className="card__title">Sao-Sao Falls</h2>
              <Link to="/tourist-destination">
                <button className="btn">See More</button>
              </Link>
            </div>
          </div>
          <div className="card">
            <img className="card__image " src={cave} alt="Card" />
            <div className="card__content">
              <h2 className="card__title">Malanoglanog Cave</h2>
              <Link to="/tourist-destination">
                <button className="btn">See More</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestNews;
